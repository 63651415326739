import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import HeroSection from '../components/HeroSection'
import OurService from '../components/OurService'
import OurProducts from '../components/OurProducts'
import HowDoesItWork from '../components/HowDoesItWork'
import WeTreatYouBetter from '../components/WeTreatYouBetter'
import PoweredByTruepill from '../components/PoweredByTruepill'
import FAQs from '../components/FAQs'
import Sources from '../components/Sources'
import { graphql } from 'gatsby'
import { pullProducts } from '../utils/graphql'

const IndexPage = ({ data }) => {
  const [products] = pullProducts([data.allContentfulProduct])

  return (
    <Layout useH2 showDisclaimer hideSignUpButton={true}>
      <SEO title="Humana" />
      <HeroSection />
      <OurService />
      <HowDoesItWork />
      <OurProducts products={products} />
      <WeTreatYouBetter />
      <PoweredByTruepill />
      <FAQs />
      <Sources />
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulProduct {
      edges {
        node {
          id
          productName {
            productName
          }
          productDescription {
            productDescription
          }
          numberOfUses
          price
          threeMonthPrice
          image {
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

export default IndexPage
