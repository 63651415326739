import React from 'react'
import { Columns, Device } from '../components/PageStructure'
import styled from 'styled-components'
import {
  ColorFlourishGreenBright,
  ColorSupernova,
  FontDesktopH2,
  FontDesktopPBody,
  ColorHumanaGrey,
  ColorTextLightGray,
} from '../styleVariables'
import Ellipse from '../assets/icons/ellipse.svg'

const StyledCircle = styled.sup`
  position: absolute;
  right: -9px;
  top: -20px;
  > svg {
    width: 8px;
    height: 8px;
    circle {
      stroke: ${ColorTextLightGray};
      stroke-width: 16;
    }
  }
`

const IconCircle = () => {
  return (
    <StyledCircle>
      <Ellipse />
    </StyledCircle>
  )
}

const OurService = () => (
  <OurServiceContainer>
    <RelativeH2>
      Our service
      <IconCircle />
    </RelativeH2>
    <Line />
    <p>
      40% of men above the age of 40 suffer from some form of erectile dysfunction<sup>1</sup>. But too often, they don’t seek the
      help they need, when they need it. We’re here to help with that.
    </p>
  </OurServiceContainer>
)

const OurServiceContainer = styled.div`
  grid-column: 4 / span 8;
  @media (max-width: ${Device.mobile}) {
    grid-column: contentStart / contentEnd;
  }
  overflow: hidden;
  padding-top: 4.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  > h2 {
    ${FontDesktopH2}
    color: ${ColorFlourishGreenBright}
  }

  > p {
    ${FontDesktopPBody}
    color: ${ColorHumanaGrey};
    sup {
      font-size: 10px;
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 4.875rem;
  }
`

const Line = styled.div`
  width: 8rem;
  height: 0rem;
  border: 0.125rem solid ${ColorSupernova};
  background: ${ColorSupernova};
  margin-bottom: 1rem;
`

const RelativeH2 = styled.h2`
  position: relative;
`

export default OurService
