import React from 'react'
import { Columns, Device } from '../components/PageStructure'
import styled from 'styled-components'
import {
  ColorPrimaryDarkGreen,
  ColorTextDarkGray,
  ColorTextLightGray,
  ColorOtherLightGray,
  ColorHumanaGrey,
  FontDesktopH3,
  FontDesktopH4,
  FontDesktopPSmall,
  FontDesktopPBody,
  FontDesktopPBodyBold,
} from '../styleVariables'
import PoweredByImage from '../components/PoweredByImage'
import { ArrowButton } from '../components/Button'
import TruepillLogo from '../assets/icons/truepill-logo.svg'
import BackgroundImage from 'gatsby-background-image'
import { navigate, useStaticQuery } from 'gatsby'
import AccreditationBadges from '../components/AccreditationBadges'

const PoweredByTruepillSection = () => (
  <StyledPoweredByTruepillSection>
    <LeftText>
      <Welcome>Powered by Truepill Telehealth</Welcome>
      <ReasonsList>
        <li>Trusted physicians doing 200K+ telehealth visits per month</li>

        <li> 20K prescriptions shipped daily</li>

        <li> Satisfied patients - 83% of patients would recommend us</li>
      </ReasonsList>
    </LeftText>
    <PoweredByImageContainer>
      <PoweredByImage />
    </PoweredByImageContainer>
    <BadgeContainer>
      <AccreditationBadges />
    </BadgeContainer>
  </StyledPoweredByTruepillSection>
)

const PoweredByTruepill = () => (
  <PoweredByTruepillContainer>
    <TruepillLogo fill={ColorOtherLightGray} />
    <p>Powered by Truepill Telehealth.</p>
  </PoweredByTruepillContainer>
)

const Welcome = styled.p`
  ${FontDesktopH3}
  color: ${ColorTextLightGray};
  margin-top: 0rem;
  margin-bottom: 0rem;
`

const GetStartedButton = styled(ArrowButton)`
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  ${FontDesktopH4}
`

const LeftText = styled.div`
  display: flex;
  flex-direction: column;
  align-contents: center;
  justify-content: center;
  grid-column: contentStart / span 5;
  padding-top: 1rem;
  padding-bottom: 0rem;
  @media (max-width: ${Device.mobile}) {
    grid-column: contentStart / contentEnd;
    grid-row: 2;
    button {
      align-self: center;
    }
  }
`

const PoweredByImageContainer = styled.div`
  grid-column: 7 / span contentEnd;
  @media (max-width: ${Device.mobile}) {
    display: none;
  }
`

const ReasonsList = styled.ul`
  margin-top: 2rem;
  padding: 0rem;
  color: ${ColorTextDarkGray};
  > li {
    ${FontDesktopPBody};
    margin: 1rem;
  }
`

const StyledPoweredByTruepillSection = styled.div`
  ${Columns};
  margin-top: 7rem;
  margin-bottom: 3rem;
  grid-column: 1 / -1;
  grid-template-rows: auto;
  align-items: stretch;
  > div:last-of-type {
    grid-column: 7 / contentEnd;
    border-radius: 0% 0% 0% 3.25rem;
    @media (max-width: ${Device.mobile}) {
      grid-column: contentStart / span 13;
      grid-row: 1;
    }
  }
`

const PoweredByTruepillContainer = styled.div`
  width: 70%;
  margin-top: 3rem;
  border-top: 1px solid ${ColorOtherLightGray};
  display: grid;
  grid-template-columns: [logo] 6.625rem [copy] 1fr;
  grid-template-rows: 2.625rem;
  > p {
    grid-row: 1;
    grid-column: copy;
    display: inline-block;
    align-self: start;
    margin-left: 1rem;
    margin-top: 0rem;
    ${FontDesktopPSmall}
    color: ${ColorHumanaGrey};
    line-height: 45px;
  }
  > svg {
    padding-top: 0.25rem;
    grid-row: 1;
    grid-column: logo;
  }
  @media (max-width: ${Device.mobile}) {
    display: none;
  }
`

const BadgeContainer = styled.div``

export default PoweredByTruepillSection
