import React from 'react'
import styled from 'styled-components'
import { Columns, Device } from '../components/PageStructure'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import {
  ColorFlourishGreenBright,
  ColorOtherPaleGray,
  ColorTextDarkGray,
  ColorTextLightGray,
  ColorPrimaryDarkGreen,
  ColorPrimaryWhite,
  FontDesktopH2,
  FontDesktopH3,
  FontDesktopH4,
  FontDesktopPBody,
  FontDesktopPBodyBold,
} from '../styleVariables'
import SignUpButton from '../components/SignUpButton'
import Ellipse from '../assets/icons/ellipse.svg'
import Transparency from '../assets/icons/transparency.svg'
import PriceTag from '../assets/icons/price-tag.svg'
import Shield from '../assets/icons/shield.svg'
import CaringHands from '../assets/icons/caring-hands.svg'

const WeTreatYouBetter = () => {
  return (
    <WeTreatYouBetterContainer>
      <StyledBackgroundImage />
      <PointCollectionContainer>
        <h2>Why Humana Men’s Health</h2>
        <PointCollection>
          <StyledPoint>
            <IconCircle>
              <Shield />
            </IconCircle>
            <h3>Online, private treatment</h3>
            <p>
              No need for awkward face-to-face conversation. Get the treatment you need with our 100% private, online
              doctor visit. All your medications arrive in discreet packaging. 
            </p>
          </StyledPoint>
          <StyledPoint>
            <IconCircle>
              <PriceTag />
            </IconCircle>
            <h3>100% performance at 10% cost</h3>
            <p>
              Sildenafil, our treatment for ED has the same active ingredient as Viagra, but it’s only a fraction of the
              cost
            </p>
          </StyledPoint>
          <StyledPoint>
            <IconCircle>
              <Transparency />
            </IconCircle>
            <h3>Transparent pricing</h3>
            <p>
              We’re 100% transparent about our pricing. We’ll never add extra charges or hide fees. So you’ll always
              know up front what you’ll need to pay.
            </p>
          </StyledPoint>
          <StyledPoint>
            <IconCircle>
              <CaringHands />
            </IconCircle>
            <h3>Care when you need it</h3>
            <p>
              No need to wait for weeks for an appointment. A licensed provider will review your medical history within
              24 hours and you can have your medication delivered next day
            </p>
          </StyledPoint>
        </PointCollection>
      </PointCollectionContainer>
    </WeTreatYouBetterContainer>
  )
}

const BackgroundImage = () => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "wetreatyoubetter.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (!data?.backgroundImage?.childImageSharp?.fluid) {
    return <div>Picture not found</div>
  }

  return <Img objectFit={'cover'} fluid={data.backgroundImage.childImageSharp.fluid} />
}

const TagLine = styled.p`
  grid-column: contentStart / contentEnd;
  align-self: center;
  justify-self: center;
  align-text: center;
  color: ${ColorTextDarkGray};
  ${FontDesktopH4}
`

const IconCircle = (props: { children: React.ReactNode }) => {
  return (
    <StyledCircle>
      <Ellipse />
      {props.children}
    </StyledCircle>
  )
}

const WeTreatYouBetterContainer = styled.div`
  grid-column: 1 / -1;
  overflow: hidden;
  ${Columns}
  > div:first-of-type {
    grid-column: 1 / -1;
    grid-row: 1;
  }
  > h2 {
    ${FontDesktopH2}
    margin: 0rem;
    color: ${ColorFlourishGreenBright};
    grid-column: contentStart / span contentEnd;
    text-align: center;
  }
`

const StyledBackgroundImage = styled(BackgroundImage)`
  grid-column: 1 / -1;
  grid-row: 1;
`
const PointCollectionContainer = styled.div`
  z-index: 1;
  grid-row: 1;
  grid-column: 3 / span 10;
  margin-top: 5rem;
  margin-bottom: 5rem;
  background-color: ${ColorPrimaryWhite};
  border-top: 0.5rem solid ${ColorFlourishGreenBright};
  padding-left: 7.5rem;
  padding-right: 7.5rem;
  padding-bottom: 5rem;
  padding-top: 5rem;
  border-radius: 0rem 0rem 0rem 2.5rem;
  @media (max-width: ${Device.mobile}) {
    grid-column: contentStart / contentEnd;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  > h2 {
    margin: 0rem;
    text-align: center;
    align-self: center;
    margin-bottom: 3rem;
    color: ${ColorPrimaryDarkGreen};
    ${FontDesktopH2}
  }
`

const PointCollection = styled.ul`
  margin-top: 0;
  padding-left: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  grid-column-gap: 2.5rem;
  grid-row-gap: 3rem;
  @media (max-width: ${Device.mobile}) {
    grid-template-columns: 1fr;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  align-items: stretch;
  justify-items: center;
`

const StyledPoint = styled.li`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  text-align: center;
  > h3 {
    margin: 1.5rem;
    ${FontDesktopH3}
    color: ${ColorTextLightGray};
  }
  > p {
    margin: 0rem;
    ${FontDesktopPBody}
    > em {
      ${FontDesktopPBodyBold}
    }
    color: ${ColorTextDarkGray};
  }
`

const StyledCircle = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  align-items: space-between;
  justify-content: center;
  justify-self: center;
  > svg {
    grid-row: 1;
    grid-column: 1;
    align-self: center;
    justify-self: center;
  }
  > p {
    grid-row: 1;
    grid-column: 1;
    z-index: 2;
    text-align: center;
    justify-self: center;
    align-self: center;
    color: ${ColorPrimaryDarkGreen};
    margin: 0rem;
    ${FontDesktopH2}
  }
`

export default WeTreatYouBetter
