import React from 'react'
import styled, { css } from 'styled-components'
import { 
  ColorOtherLightGray,
  ColorTextDarkGray,
  ColorTextLightGray,
  FontDesktopH5,
  FontDesktopPSmall
} from '../../styleVariables'

export const ErrorStyle = css`
  border: 1px solid #AA0008;
  box-sizing: border-box;
  border-radius: 5px;
`

export const CommonFont = css`
  font-family: 'HumanaRegular';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
`

export const InputTitle = styled.div`
  ${CommonFont}
  line-height: 24px;
  grid-row: 1;
`

export const GroupedInput = styled.div`
  display: grid;
`

export const Details = styled.details`
  padding-top: 2rem;
  padding-right: 1rem;
  ${FontDesktopPSmall}
  color: ${ColorTextLightGray};
  border-top: 1px solid ${ColorOtherLightGray};
  padding-bottom: 2rem;
  :last-of-type {
    border-bottom: 1px solid ${ColorOtherLightGray};
  }
  &[open] {
    > summary {
      padding-bottom: 2rem;
      > svg {
        transform: rotate(180deg);
      }
    }
  }
  > summary {
    color: ${ColorTextDarkGray};
    align-items: center;
    ${FontDesktopH5};
    font-size: 1.1rem;
    display: flex;
    justify-content: space-between;
    line-height: 1.4;

    > svg {
      margin-left: 1rem;
      transition: 200ms;
    }
    cursor: pointer;
    list-style: none;

    ::-webkit-details-marker {
      display: none; /* Chrome */
    }
  }

  @media(min-width: 768px) {
    > summary {
      ${FontDesktopH5};
    }
  }
`
