import React from 'react'
import styled from 'styled-components'
import { Columns, Device } from '../components/PageStructure'
import {
  ColorFlourishGreenBright,
  ColorOtherPaleGray,
  ColorTextLightGray,
  ColorTextDarkGray,
  ColorFountainBlue,
  ColorPrimaryWhite,
  FontDesktopH2,
  FontDesktopH4,
  FontDesktopPBody,
  FontDesktopPBodyBold,
} from '../styleVariables'
import { ProductShot } from '../components/Product'
import { Product, AvailableProducts } from '../types'
import { navigate } from 'gatsby'
import Button from '../components/Button'

const OurProducts = (props: { products: AvailableProducts }) => {
  const { products } = props
  // Hard coded for now
  const product = products['Sildenafil']
  return (
    <OurProductsContainer>
      <h2>FDA approved treatment</h2>
      <ProductCollection>
        <StyledProductShot product={product} />
        <ProductDescription>
          <h3>
            Sildenafil, Generic for Viagra<span>®</span>
          </h3>
          <ProductCopy>
            Sildenafil is used to treat impotence, or erectile dysfunction, in adult men and is approved by FDA. It
            relaxes muscles of the blood vessels and increases blood flow to the penis to allow for a harder and
            longer-lasting erection.
          </ProductCopy>
        </ProductDescription>
      </ProductCollection>
    </OurProductsContainer>
  )
}

const OurProductsContainer = styled.div`
  grid-column: 1 / -1;
  ${Columns}
  background-color: ${ColorPrimaryWhite};
  > h2 {
    ${FontDesktopH2}
    color: ${ColorFlourishGreenBright};
    grid-column: contentStart / span contentEnd;
    text-align: center;
    margin-bottom: 4rem;
  }

  margin-bottom: 6rem;
`

const StyledProductShot = styled(ProductShot)`
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  background-color: ${ColorFountainBlue};
`

const GetStartedButton = styled(Button)`
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  width: auto;
  ${FontDesktopH4}
`

const Bar = styled.div`
  height: 1.5rem;
  border-left: 0.05125rem solid ${ColorPrimaryWhite};
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  opacity: 0.5;
`

const GetStarted = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  > * {
    margin-right: 1rem;
  }
  > p {
    ${FontDesktopPBody}
  }
  @media (max-width: ${Device.tiny}) {
    flex-direction: column;
    align-self: center;
    > p {
      align-self: center;
    }
  }
`

const ProductDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  > h3 {
    ${FontDesktopH2}
    color: ${ColorTextDarkGray};
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    > span {
      margin: 0rem;
      font-size: 1rem;
      align-self: flex-start;
      line-height: 1;
    }
  }
  > * {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  @media (max-width: ${Device.micro}) {
    width: 310px;
  }
`

const ProductCopy = styled.p`
  ${FontDesktopPBody}
  color: ${ColorTextDarkGray};
`

const ProductCollection = styled.div`
  display: grid;
  grid-row: 2;
  grid-column: contentStart / span contentEnd;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${Device.mobile}) {
    grid-column: contentStart / contentEnd;
    grid-template-columns: 1fr;
  }
  grid-auto-flow: row;
  grid-column-gap: 2rem;
  grid-row-gap: 1.875rem;
  align-items: center;
  justify-items: center;
`

export default OurProducts
