import React from 'react'
import styled from 'styled-components'
import Img, { GatsbyImageFluidProps } from 'gatsby-image'
import {
  ColorPrimaryWhite,
  ColorTextLightGray,
  ColorButtonBlue,
  FontDesktopPBodyBold,
  FontDesktopH4,
} from '../styleVariables'
import { Columns } from '../components/PageStructure'
import { Product } from '../types'

const ProductView = (props: {product: Product, className?: string}) => {
  const {className, product} = props
  return (
    <ProductContainer className={className}>
      <ImageContainer>
        <Img objectFit="cover" fluid={product.image[0].fluid} />
      </ImageContainer>
      <TextContainer>
        <p>{product.productName.productName}</p>
        <h4>{product.productDescription.productDescription}</h4>
      </TextContainer>
    </ProductContainer>
  )
}

export const ProductShot = (props: { product: Product; className?: string }) => {
  return (
    <StyledProductShot className={props.className}>
      <Img objectFit="cover" fluid={props.product.image[0].fluid} />
    </StyledProductShot>
  )
}

const ProductContainer = styled.div`
  display: grid;
  justify-self: stretch;
  grid-template-columns: [image] minmax(2rem, 0.5fr) [content] 1fr;
`

const TextContainer = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  grid-row: content;
  background-color: ${ColorPrimaryWhite};
  > p {
    ${FontDesktopPBodyBold}
  }
  > h4 {
    ${FontDesktopH4}
    color: ${ColorButtonBlue};
    padding-bottom: 2rem;
  }
`

const ImageContainer = styled.div`
  background-color: ${ColorTextLightGray};
  grid-row: image;
  min-width: 0;
  overflow: hidden;
`

const StyledProductShot = styled.div`
  min-width: 0;
  overflow: hidden;
`

export default ProductView
