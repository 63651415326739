import React from 'react'
import styled from 'styled-components'
import { Columns, Device } from '../components/PageStructure'
import { Link } from 'gatsby'
import {
  ColorPrimaryWhite,
  ColorPrimaryDarkGreen,
  ColorOtherLightGray,
  ColorTextDarkGray,
  ColorTextLightGray,
  ColorButtonBlue,
  FontDesktopH2,
  FontDesktopPBodyBold,
  FontDesktopH5,
  FontDesktopPSmall
} from '../styleVariables'
import { Details } from './styles/common'

import Caret from '../assets/icons/caret.svg'

const FAQs = () => (
  <FAQsContainer>
    <h2>FAQs</h2>
    <StyledDetails>
      <SummaryBlock>
        What causes ED?
        <Caret />
      </SummaryBlock>
      <p>Many factors can cause erectile dysfunction including:</p>
      <ul>
        <li>Medical conditions and ailments such as diabetes, high cholesterol and high blood pressure.</li>
        <li>
          Being aged over 50 - although it’s not uncommon for men to suffer from erectile dysfunction in their 40s, 30s
          and even 20s.
        </li>
        <li>
          Obesity is a risk factor for erectile dysfunction, as with many other medical conditions. It is advisable to
          maintain a healthy weight through diet and lifestyle choices.
        </li>
        <li>
          Medication prescribed to lower blood pressure and beta blockers can lower blood pressure in the penis. This
          can make it difficult to get and maintain an erection. Always inform your doctor of all medication you
          currently take – this includes completing an online consultation.
        </li>
      </ul>
    </StyledDetails>
    <StyledDetails>
      <SummaryBlock>
        How does erectile dysfunction treatment work through Humana?
        <Caret />
      </SummaryBlock>
      <p>
        Thanks to digital technology, treating erectile dysfunction is now discreet, convenient and affordable. It
        involves just a few easy steps:
      </p>
      <ul>
        <li>Complete our questionnaire</li>
        <li>Upload your photo ID</li>
        <li>Add your recent blood pressure reading</li>
      </ul>
      <p>
        One of our US licensed physicians will then assess your answers and determine whether you are a good candidate
        for erectile dysfunction treatment.
      </p>
    </StyledDetails>
    <StyledDetails>
      <SummaryBlock>
        Is this a safe way to receive treatment?
        <Caret />
      </SummaryBlock>
      <p>
        Telemedicine relies on patients giving full and truthful answers to the best of their knowledge. As long as this
        is the case, telemedicine is a safe way to receive treatment. So treat a telemedicine consultation exactly as
        you would if you were speaking to a doctor or nurse face to face. This ensures we can accurately assess your
        medical and personal history - and we can consider a safe and effective treatment that has the best possible
        chance of working for you.
      </p>
      <p>
        For the same reason, it’s important to carefully follow all instructions when you upload photos of yourself and
        your ID.
      </p>
    </StyledDetails>
    <StyledDetails>
      <SummaryBlock>
        When can I expect a response from my medical provider?
        <Caret />
      </SummaryBlock>
      <p>
        Generally, the healthcare providers will approve or reject your prescription request within within 24 hours, often sooner.
        If you have any questions, please email us{' '}
        <a href={'mailto:humana@truepill.com'}>humana@truepill.com</a> or call us at{' '}
        <a href={'tel:855-645-8114'}>855-645-8114</a>
      </p>
    </StyledDetails>
    <StyledDetails>
      <SummaryBlock>
        How effective are medications used to treat erectile dysfunction?
        <Caret />
      </SummaryBlock>
      <p>
        These medications can be very effective for many patients. <a href="https://www.accessdata.fda.gov/drugsatfda_docs/label/2014/20895s039s042lbl.pdf">Around 82% of men</a> will see some benefit from using
        erectile dysfunction treatments. Men who suffer mild-to-moderate erectile dysfunction often experience great
        benefits.
      </p>
    </StyledDetails>
    <StyledDetails>
      <SummaryBlock>
        How long does Sildenafil last?
        <Caret />
      </SummaryBlock>
      <p>
        Sildenafil can take around half an hour to work - up to around an hour. The effects of sildenafil usually last
        approximately two to three hours. However, the effects can vary from one person to the next - some people find
        the effects can last for four to six hours.
      </p>
      <p>
        If you notice the effects beginning to wear off, it’s important NOT to take another dose until 24 hours after
        your last one - even if the effects begin wearing off sooner than you would like.
      </p>
    </StyledDetails>
    <StyledDetails>
      <SummaryBlock>
        What are Sildenafil’s side effects?
        <Caret />
      </SummaryBlock>
      <p>
        Side effects are unusual when taking sildenafil - and when they do occur, it’s likely they will be mild.
        However, reported side effects include hot flush or facial flushing, feelings of nausea, blurred vision,
        dizziness and indigestion.
      </p>
    </StyledDetails>
    <StyledDetails>
      <SummaryBlock>
        How much does a visit and medication cost?
        <Caret />
      </SummaryBlock>
      <p>We charge:</p>
      <ul>
        <li>
          <p>One-time provider visit fee of $15.</p>
        </li>
        <li>
          <p>
            Subscription of Sildenafil is $18 for 4 uses, $27 for 6 uses, $36 for 8 uses or $45 for 10 uses. 
            You can get your shipment monthly or quarterly. Quarterly shipments get a discount relative to prices above.
          </p>
        </li>
        <li>
          <p>
            Standard shipping is free of charge (7-10 days, often sooner); Express shipping (2 days) for $20 and Next day shipping for $40.
          </p>
        </li>
      </ul>
    </StyledDetails>
    <StyledDetails>
      <SummaryBlock>
        Can I use insurance to pay for my visit?
        <Caret />
      </SummaryBlock>
      <p>This service is off benefit and not covered by your insurance</p>
    </StyledDetails>
    <StyledDetails>
      <SummaryBlock>
        How does my order ship and how long does it take to get to me?
        <Caret />
      </SummaryBlock>
      <p>
        Order ships in discrete polybubble packaging with all medications in plastic vials that are perfect for your
        medicine cabinet or nightstand.
      </p>
      <p>
        Standard shipping is free of charge (7-10 days, often sooner); Express shipping (2 days) for $20 and Next day shipping for $40.
      </p>
    </StyledDetails>
    <Link to='/faqs'>Read more</Link>

    <DisclaimerBlock>
      This information is provided for educational purposes only. It is not to be used for medical advice, diagnosis or treatment. Consult your healthcare provider if you have questions or concerns. If you are in a life-threatening or emergency medical situation, please dial 9-1-1 and seek medical attention immediately.
    </DisclaimerBlock>
  </FAQsContainer>
)

const StyledDetails = styled(Details) `
  grid-column: 4 / span 8;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  justify-self: stretch;

  @media (max-width: ${Device.mobile}) {
    grid-column: contentStart / contentEnd;
  }
`

const FAQsContainer = styled.div`
  margin-top: 5rem;
  margin-bottom: 7rem;
  grid-column: 1 / -1;
  ${Columns}
  background-color: ${ColorPrimaryWhite};
  > h2 {
    ${FontDesktopH2}
    color: ${ColorPrimaryDarkGreen};
    margin: 0rem;
    grid-column: contentStart / span contentEnd;
    text-align: center;
    margin-bottom: 4rem;
  }
  > a {
    ${FontDesktopPBodyBold}
    padding-top: 2rem;
    grid-column: 4 / span 8;
    text-align: center;
    color: ${ColorButtonBlue};
  }
`
const SummaryBlock = styled.summary`
  outline: none;
`

const DisclaimerBlock = styled.summary`
  outline: none;
  display: grid;
  grid-column: 4/12;
  margin-top: 50px;
  line-height: 24px;
  color: ${ColorTextLightGray};
`

export default FAQs
