import React from 'react'
import styled from 'styled-components'
import {
  ColorSupernova,
  ColorHumanaGrey,
} from '../styleVariables'

export const Sources = () => {
  return (
    <Container>
      <StyledH2>
        Sources:
      </StyledH2>
      <StyledSourceList>
        <li>
          Johannes CB, Araujo AB, Feldman HA, Derby CA, Kleinman KP, McKinlay JB. Incidence of erectile dysfunction in men 40 to 69 years old: Longitudinal results from the Massachusetts Male Aging Study. J Urol 2000; 163:460–463.”
        </li>
      </StyledSourceList>
    </Container>
  )
}

const Container = styled.section`
  margin-bottom: 5rem;
  grid-column: 1/-1;
  grid-template-columns: repeat(12,1fr);
  display: grid;
  
  @media(min-width: 769px) {
    h1 {
      margin-bottom: 3rem;
    }
  }
`

const StyledH2 = styled.h2`
  display: grid;
  grid-row: 1;
  grid-column: 2/12;
  font-weight: bold;
  color: ${ColorHumanaGrey};
  font-size: 26px;
  width: 135px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  border-bottom: 4px solid  ${ColorSupernova};
`

const StyledSourceList = styled.ol`
  grid-row: 2;
  grid-column: 2/12;
  padding-inline-start: 16px;
  width: 100%;
`

export default Sources
