import React from 'react'
import { Columns, Device } from '../components/PageStructure'
import styled from 'styled-components'
import {
  ColorPrimaryDarkGreen,
  ColorTextDarkGray,
  ColorOtherLightGray,
  ColorHumanaGrey,
  FontDesktopH1,
  FontDesktopPSmall,
  FontDesktopPBodyBold,
} from '../styleVariables'
import HeroImage from '../components/HeroImage'
import Button from '../components/Button'
import TruepillLogo from '../assets/icons/truepill-logo.svg'
import { navigate } from 'gatsby'

const HeroSection = () => (
  <StyledHeroSection>
    <LeftText>
      <Welcome>Welcome Humana members</Welcome>
      <ProductTitleText>Erectile dysfunction treatment to help you get back to your best</ProductTitleText>
      <PoweredByTruepill />
    </LeftText>
    <HeroImage />
  </StyledHeroSection>
)

const PoweredByTruepill = () => (
  <PoweredByTruepillContainer>
    <TruepillLogo fill={ColorOtherLightGray} />
    <p>Powered by Truepill Telehealth.</p>
  </PoweredByTruepillContainer>
)

const Welcome = styled.p`
  ${FontDesktopPBodyBold}
  color: ${ColorTextDarkGray};
  margin-top: 2rem;
  margin-bottom: 0rem;
  text-align: center;

  @media(min-width: 768px) {
    text-align: left;
  }
`

const LeftText = styled.div`
  display: flex;
  flex-direction: column;
  align-contents: center;
  justify-content: center;
  grid-column: contentStart / span 6;
  @media (max-width: ${Device.mobile}) {
    grid-column: contentStart / contentEnd;
    grid-row: 2;
    button {
      align-self: center;
    }
  }
`

const ProductTitleText = styled.h2`
  color: ${ColorPrimaryDarkGreen};
  font-size: 1.7rem;
  text-align: center;
  margin-top: 1.5rem;

  @media (min-width: 768px) {
    ${FontDesktopH1};
    text-align: left;
  }
`

const StyledHeroSection = styled.div`
  ${Columns};
  grid-column: 1 / -1;
  grid-template-rows: auto;
  align-items: stretch;
  > div:last-of-type {
    grid-column: 8 / contentEnd;
    border-radius: 0% 0% 0% 3.25rem;
    @media (max-width: ${Device.mobile}) {
      grid-column: contentStart / span 13;
      grid-row: 1;
    }
  }
`

const PoweredByTruepillContainer = styled.div`
  width: 70%;
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid ${ColorOtherLightGray};
  display: grid;
  grid-template-columns: [logo] 6.625rem [copy] 1fr;
  grid-template-rows: 2.625rem;
  > p {
    grid-row: 1;
    grid-column: copy;
    display: inline-block;
    align-self: start;
    margin-left: 1rem;
    margin-top: 0rem;
    ${FontDesktopPSmall}
    color: ${ColorHumanaGrey};
    line-height: 45px;
  }
  > svg {
    padding-top: 0.25rem;
    grid-row: 1;
    grid-column: logo;
  }
  @media (max-width: ${Device.mobile}) {
    display: none;
  }
`

export default HeroSection
