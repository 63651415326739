import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Device } from '../components/PageStructure'
import styled from 'styled-components'
import Img from 'gatsby-image'


const AccreditationBadges = () => {
  const data = useStaticQuery(graphql`
    query {
      uracSpecialty: file(relativePath: { eq: "URAC_Horizontal_Specialty_Black.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      uracMail: file(relativePath: { eq: "URAC_Horizontal_MailService_Black.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nabp: file(relativePath: { eq: "NABP_Accredited_Digital_Pharmacy.webp" }) {
        childImageSharp {
 fluid(maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (!data?.uracSpecialty?.childImageSharp?.fluid) {
    return <div>Images not found</div>
  }

  return (
    <AccreditationBadgeContainer>
      <Img fluid={data.uracSpecialty.childImageSharp.fluid} />
      <Img fluid={data.uracMail.childImageSharp.fluid} />
      <Img fluid={data.nabp.childImageSharp.fluid} />
    </AccreditationBadgeContainer>
  )
}

const AccreditationBadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;
  padding-right: 4rem;
  padding-left: 4rem;
  margin-bottom: 3rem;
  > div {
    min-width: 9rem;
    &:last-of-type {
      min-width: 7rem;

    }
  }
  @media (max-width: ${Device.mobile}) {
    display: none;
  }
`

export default AccreditationBadges

