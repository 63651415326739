import React from 'react'
import styled from 'styled-components'
import { Columns, Device } from '../components/PageStructure'
import {
  ColorPrimaryWhite,
  ColorFlourishGreenBright,
  ColorOtherPaleGray,
  ColorTextDarkGray,
  ColorTextLightGray,
  ColorPrimaryDarkGreen,
  FontDesktopH2,
  FontDesktopH3,
  FontDesktopH4,
  FontDesktopPBody,
  FontDesktopPBodyBold,
} from '../styleVariables'
import SignUpButton from '../components/SignUpButton'
import Ellipse from '../assets/icons/ellipse.svg'

const HowDoesItWork = () => {
  return (
    <>
      <HowDoesItWorkContainer>
        <Anchor id={'HowDoesItWork'} />
        <SectionTitle>A happier you in three simple steps</SectionTitle>
        <PointCollection>
          <Point index={1}>
            <RelativeH3>
              Online doctor visit
              <DisclaimerSquare />
            </RelativeH3>
            <p>Confidentially share your symptoms and medical history with a  US-based licensed clinician.</p>
          </Point>
          <Point index={2}>
            <h3>Free, discreet shipping</h3>
            <p>Free shipping of your medication to your doorstep in discreet packaging. </p>
          </Point>
          <Point index={3}>
            <h3>Ongoing care</h3>
            <p>Communicate with your care team anytime to discuss questions and concerns.</p>
          </Point>
        </PointCollection>
      </HowDoesItWorkContainer>
    </>
  )
}

const Anchor = styled.div`
  opacity: 0;
  position: relative;
  top: -6rem;
`

const Point = (props: { index: number; children: React.ReactNode }) => (
  <StyledPoint>
    <NumberCircle index={props.index} />
    {props.children}
  </StyledPoint>
)

const NumberCircle = (props: { index: number }) => {
  return (
    <StyledCircle>
      <Ellipse />
      <p>{props.index}</p>
    </StyledCircle>
  )
}

const RelativeH3 = styled.h3`
  position: relative;
`

const DisclaimerSquare = styled.span`
  position: absolute;
  margin-left: 5px;
  border: 1px solid ${ColorTextLightGray};
  width: 8px;
  height: 8px;
  box-sizing: border-box;
  transform: rotate(-45deg);
`

const SectionTitle = styled.h2`
  ${FontDesktopH2}
  margin: 0rem;
  color: ${ColorFlourishGreenBright};
  grid-column: contentStart / span contentEnd;
  text-align: center;
  margin-top: 1.375rem;
  margin-bottom: 1.375rem;
`

const HowDoesItWorkContainer = styled.div`
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
  grid-column: 1 / -1;
  ${Columns}
  background-color: ${ColorOtherPaleGray};
`

const PointCollection = styled.ul`
  padding-inline-start: 0rem;
  display: grid;
  grid-column: contentStart / contentEnd;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: row;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  @media (max-width: ${Device.mobile}) {
    grid-template-columns: 1fr;
  }
  align-items: flex-start;
  justify-items: center;
`

const StyledPoint = styled.li`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  text-align: center;
  > h3 {
    margin: 1.5rem;
    ${FontDesktopH3}
    color: ${ColorTextLightGray};
  }
  > p {
    margin: 0rem;
    ${FontDesktopPBody}
    > em {
      ${FontDesktopPBodyBold}
    }
    color: ${ColorTextDarkGray};
  }
`

const StyledCircle = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  align-items: space-between;
  justify-content: center;
  justify-self: center;
  > svg {
    grid-row: 1;
    grid-column: 1;
    align-self: center;
    justify-self: center;
  }
  > p {
    grid-row: 1;
    grid-column: 1;
    z-index: 2;
    text-align: center;
    justify-self: center;
    align-self: center;
    color: ${ColorPrimaryDarkGreen};
    margin: 0rem;
    ${FontDesktopH2}
  }
`

export default HowDoesItWork
